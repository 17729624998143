#recruit{

	// page__title --------------------
	.page__title{
		background: url("/assets/img/recruit/page_title_bg.jpg") no-repeat center top;
		background-size: cover;

		@include max_screen($break-point) {
		}
	}

	.recruit{
		width: 960px;
		margin: 0 auto;
		padding-bottom: 150px;

		@include max_screen($break-point) {
			width: 100%;
			padding: 0 30px 70px 30px;
			box-sizing: border-box;
		}

		&__catch{
			background: #f4f4f4;
			padding: 30px 40px;
			line-height: percentage(34/16);
			font-size: 16px;
			letter-spacing: .025em;
			text-align: left;
			margin-bottom: 77px;

			@include max_screen($break-point) {
				padding: 20px 20px;
				margin-bottom: 40px;
			}
		}

		&__table{
			border-collapse: collapse;
			margin-bottom: 65px;

			th{
				font-size: 14px;
				letter-spacing: .025em;
				text-align: left;
				font-weight: normal;
				line-height: percentage(24/14);
				background: #f4f4f4;
				border: 1px solid #ccc;
				padding: 13px 20px;
				box-sizing: border-box;
				width: 185px;
				vertical-align: top;
			}

			td{
				font-size: 14px;
				letter-spacing: .025em;
				line-height: percentage(24/14);
				text-align: left;
				font-weight: normal;
				border: 1px solid #ccc;
				padding: 13px 20px;
				box-sizing: border-box;
				width: 775px;
				vertical-align: top;
			}

			@include max_screen($break-point) {
				margin-bottom: 50px;

				th{
					width: 40%;
				}

				td{
					width: 60%;
				}
			}
		}

		.contact__btn{
			width: 270px;
			height: 70px;
			margin: 0 auto;
			box-sizing: border-box;
			@include txt_link_opacity(none,#fff,#004986,none);

			a{
				display: block;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 20px;
				letter-spacing: .025em;
				color: #fff;
				font-weight: 700;
				border: 1px solid #004986;
				background: #004986;

				&:hover{
					background: #fff;
					opacity: 1;
				}
			}

			@include max_screen($break-point) {
				width: 250px;
				height: 60px;
			}
		}
	}
}
// #recruit
