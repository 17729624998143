#top{

	// mv --------------------
	.mv{
		position: relative;
		margin-bottom: 87px;

		img{
			width: 100%;
		}

		&__catch{
			width: 650px;
			font-size: 19px;
			letter-spacing: .15em;
			text-indent: .15em;
			line-height: percentage(36/19);
			background: rgba(255,255,255,0.7);
			padding: 18px 0;
			box-sizing: border-box;
			font-family: $font-mincyo;
			font-weight: 500;
			text-align: center;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateY(-50%) translateX(-50%);
			z-index: 10;
		}

		@include max_screen($break-point) {
			margin-bottom: 40px;

			&__catch{
				width: 80%;
				font-size: 12px;
				padding: 15px;
				text-align: left;
			}
		}
	}


	// philosophy --------------------

	.philosophy{
		margin-bottom: 80px;
		padding-top: 60px;
		margin-top: -60px;

		.sect__ttl{
			margin-bottom: 75px;
		}

		&__catch{
			letter-spacing: .2em;
			text-indent: .2em;
			text-align: center;
			font-family: $font-mincyo;
			margin-bottom: 76px;

			.big{
				font-size: 36px;
				display: block;
				margin-bottom: 36px;
				line-height: 1;
				display: block;
			}

			.txt{
				font-size: 20px;
				line-height: percentage(36/20);
				display: block;
			}
		}

		&__box{
			background: #eee;
			border-radius: 10px;
			padding: 60px 100px;
			box-sizing: border-box;
			max-width: 660px;
			margin: 0 auto;

			&__item{
				position: relative;
				box-sizing: border-box;
				border-radius: 10px;
				font-size: 20px;
				letter-spacing: .1em;
				padding: 8px 0 8px 120px;
				font-weight: 700;
				margin-bottom: 40px;

				&:last-of-type{
					margin-bottom: 0;
				}

				.circle{
					width:60px;
					height:60px;
					border-radius: 60px;
					font-size: 18px;
					color: #fff;
					font-weight: 700;
					text-align: center;
					line-height: 60px;
					letter-spacing: .1em;
					text-indent: .1em;
					position: absolute;
					left: 30px;
					top: -7px;
				}

				&.item1{
					background: #fcb8bd;

					.circle{
						background: #f92b3b;
					}
				}

				&.item2{
					background: #a6cdfc;

					.circle{
						background: #1470df;
					}
				}

				&.item3{
					background: #fbc7a3;

					.circle{
						background: #f88535;
					}
				}

				&.item4{
					background: #adebaa;

					.circle{
						background: #02be56;
					}
				}
			}
		}

		@include max_screen($break-point) {
			margin-bottom: 60px;
			padding-top: 30px;
			margin-top: -30px;

			.sect__ttl{
				margin-bottom: 50px;
			}

			&__catch{
				margin-bottom: 40px;
				text-align: left;

				.big{
					font-size: 26px;
					margin-bottom: 20px;
					text-align: center;
				}

				.txt{
					font-size: 18px;
				}
			}

			&__box{
				padding: 40px 20px 40px 40px;

				&__item{
					font-size: 15px;
					padding: 10px 10px 10px 35px;
					margin-bottom: 40px;

					.circle{
						width:50px;
						height:50px;
						border-radius: 50px;
						font-size: 14px;
						line-height: 50px;
						left: -25px;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}
		//SP
	}

	// policy --------------------
	.policy{
		margin-bottom: 152px;

		&__catch{
			font-size: 16px;
			font-weight: 700;
			letter-spacing: .1em;
			line-height: percentage(36/16);
			margin-top: 50px;
			margin-bottom: 38px;
		}

		&__txt{
			font-size: 15px;
			letter-spacing: .1em;
			line-height: percentage(30/15);
			margin-bottom: 37px;
		}

		&__guideline{

			&__ttl{
				font-size: 15px;
				letter-spacing: .1em;
				line-height: 1;
				margin-bottom: 18px;
				font-weight: 700;
			}

			&__list{
				margin-bottom: 30px;

				li{
					padding-left: 1em;
					text-indent: -1em;
					font-size: 15px;
					letter-spacing: .1em;
					line-height: percentage(30/15);
					margin-bottom: 30px;

					&:last-child{
						margin-bottom: 0;
					}

					.protection{

						li{
							padding-left: 3em;
							text-indent: -3em;
							font-size: 15px;
							letter-spacing: .1em;
							line-height: percentage(30/15);
							margin-bottom: 0;

							&:last-child{
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}

		&__sign{
			text-align: right;
			font-size: 15px;
			letter-spacing: .1em;
			line-height: percentage(30/15);
		}

		@include max_screen($break-point) {
			margin-bottom: 75px;

			&__catch{
				font-size: 14px;
				margin-top: 50px;
				margin-bottom: 38px;
			}

			&__txt{
				font-size: 14px;
				margin-bottom: 37px;
			}

			&__guideline{

				&__ttl{
					font-size: 15px;
					margin-bottom: 18px;
				}

				&__list{
					margin-bottom: 20px;

					li{
						font-size: 14px;
						margin-bottom: 20px;

						.protection{

							li{
								font-size: 14px;
							}
						}
					}
				}
			}

			&__sign{
				font-size: 14px;
			}
		}
		//SP
	}

	.map{
		line-height: 1;

		@include max_screen($break-point) {
			iframe{
				height: auto;
			}
		}
	}
}
//top
