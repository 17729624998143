#privacy_policy{

	// page__title --------------------
	.page__title{
		background: url("/assets/img/work/page_title_bg.jpg") no-repeat center top;
		background-size: cover;

		@include max_screen($break-point) {
		}
	}

	.privacy_policy{
		width: 960px;
		margin: 0 auto;
		padding-bottom: 114px;

		@include max_screen($break-point) {
			width: 100%;
			padding: 0 30px 70px 30px;
			box-sizing: border-box;
		}

		.txt{
			font-size: 14px;
			text-align: left;
			margin-bottom: 30px;
			line-height: 1.6;

			.heading{
				display: block;
				font-weight: bold;
				font-size: 16px;
				margin-bottom: 10px;
			}
		}
	}
}
// #work
