#results{

	// page__title --------------------
	.page__title{
		background: url("/assets/img/results/page_title_bg.jpg") no-repeat center top;
		background-size: cover;

		@include max_screen($break-point) {
		}
	}

	.results{
		width: 960px;
		margin: 0 auto;
		padding-bottom: 144px;

		@include max_screen($break-point) {
			width: 100%;
			padding: 0 30px 70px 30px;
			box-sizing: border-box;
		}

		&__txt{
			font-size: 14px;
			line-height: percentage(26/14);
			margin-bottom: 29px;
		}

		&__list{
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 3px;

			&__item{
				width: percentage(300px/960px);
				max-width: 300px;
				margin-right: 30px;
				margin-bottom: 18px;

				&:nth-of-type(3n){
					margin-right: 0;
				}

				.img{
					margin-bottom: 7px;
				}

				.txt{
					font-size: 14px;
					line-height: percentage(26/14);

					.txt_red{
						color: #ff0000;
					}
				}
			}

			@include max_screen($break-point) {

				&__item{
					width: 100%;
					max-width: 100%;
					margin-right: 0;
					margin-bottom: 30px;

					&:nth-of-type(3n){
						margin-right: 0;
					}

					.img{
						width: 100%;
						margin-bottom: 10px;

						img{
							width: 100%;
						}
					}
				}
			}
		}

		&__list2{
			display: flex;
			flex-wrap: wrap;

			&__item{
				width: percentage(400px/960px);
				max-width: 400px;
				margin-right: 30px;

				&:last-of-type{
					margin-right: 0;
				}

				.img{
					margin-bottom: 10px;
				}

				.txt{
					font-size: 14px;
					line-height: percentage(26/14);

					.txt_red{
						color: #ff0000;
						font-size: 17px;
					}
				}
			}

			@include max_screen($break-point) {

				&__item{
					width: 100%;
					max-width: 100%;
					margin-right: 0;
					margin-bottom: 30px;

					&:nth-of-type(3n){
						margin-right: 0;
					}

					.img{
						width: 100%;
						margin-bottom: 10px;

						img{
							width: 100%;
						}
					}
				}
			}
		}
	}
}
// #results
