#contact{

	// page__title --------------------
	.page__title{
		background: url("/assets/img/recruit/page_title_bg.jpg") no-repeat center top;
		background-size: cover;

		@include max_screen($break-point) {
		}
	}

	// contact_form -----------------------------------------------
	.contact_form{
		margin-bottom: 130px;

		@include max_screen($break-point) {
			margin-bottom: 65px;
		}

		table{
			width: 700px;
			margin: 0 auto 30px auto;
			border-collapse: collapse;

			@include max_screen($break-point) {
				width: 100%;
			}

			th{
				width: percentage(210px / 700px);
				font-size: 15px;
				vertical-align: middle;
				padding-left: 20px;
				background: #f7f7f7;
				border: 1px solid #ddd;
				font-weight: normal;
				box-sizing: border-box;

				:after{
					content: "※必須";
					font-size: 12px;
					color: #cc0000;
					display: inline-block;
					margin-left: 5px;
				}

				@include max_screen($break-point) {
					width: 100%;
					display: block;
					padding: 10px;
					border-bottom: none;
				}
			}

			td{
				font-size: 15px;
				padding: 15px;
				border: 1px solid #ddd;
				box-sizing: border-box;

				@include max_screen($break-point) {
					width: 100%;
					display: block;
					border-bottom: none;
					padding: 10px;
				}

				small{
					font-size: 13px;
					color: #cc0000;
					margin-top: 5px;
					display: inline-block;
				}

				input{
					-webkit-appearance: none;
					border-radius: 0;
					border: 1px solid #eee;
					width: 100%;
					box-sizing: border-box;
					height: 30px;
				}

				textarea{
					border: 1px solid #eee;
					width: 100%;
					max-width: 460px;
					min-width: 460px;
					min-height: 150px;
					box-sizing: border-box;

					@include max_screen($break-point) {
						min-width: 100%;
					}
				}
			}

			tr:last-child{
				td{
					@include max_screen($break-point) {
						border-bottom: 1px solid #ddd;
					}
				}
			}
		}

		.kakunin{
			width: 250px;
			height: 50px;
			margin: 0 auto;
		}

		.kakunin input{
			-webkit-appearance: none;
			border-radius: 0;
			background: #004986;
			border: 1px solid #004986;
			color: #fff;
			font-weight: bold;
			width: 100%;
			height: 100%;
			font-size: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;

			&:hover{
				background: none;
				box-sizing: border-box;
				color: #004986;
				cursor: pointer;
			}
		}
	}

	.check_annotation{
		font-weight: bold;

		.inner{
			margin-bottom: 40px;
		}

		@include max_screen($break-point) {
			text-align: left;
		}
	}

	// thanks_txt -----------------------------------------------
	.thanks_txt{

		.lead-txt{
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 30px;

			@include max_screen($break-point) {
				margin-bottom: 20px;
			}
		}

		.txt{
			text-align: left;
			line-height: 1.6;
		}
	}

	.top_btn{
		-webkit-appearance: none;
		border-radius: 0;
		background: #004986;
		color: #fff;
		font-weight: bold;
		display: block;
		text-align: center;
		width: 250px;
		height: 50px;
		margin: 60px auto 0 auto;
		font-size: 16px;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;

		&:visited,
		&:link{
			background: #004986;
			color: #fff;
			text-decoration: none;
		}

		&:hover{
			background: none;
			border: 1px solid #004986;
			box-sizing: border-box;
			color: #004986;
			cursor: pointer;
			text-decoration: none;
		}

		@include max_screen($break-point) {
			margin: 30px auto 0 auto;
		}
	}
}
// #contact
