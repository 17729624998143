// parts --------------------
.inner{
	width: $contents-width;
	margin: 0 auto;

	@include max_screen($break-point) {
		margin: 0 auto;
		padding: 0 30px;
		box-sizing: border-box;
		width: 100%;
	}
}

.sect__ttl{

	.jp{
		font-size: 26px;
		line-height: 1;
		letter-spacing: .08em;
		text-indent: .08em;
		display: block;
		padding-bottom: 20px;
		position: relative;
		text-align: center;
		font-weight: 700;
		margin-bottom: 18px;

		&:after{
			content: "";
			display: block;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 40px;
			height: 2px;
			background: #0268a9;
		}
	}

	.en{
		font-size: 10px;
		line-height: 1;
		letter-spacing: .08em;
		text-indent: .08em;
		display: block;
		text-align: center;
	}

	@include max_screen($break-point) {
		.jp{
			font-size: 20px;
		}
	}
}

.page__title{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 70px;
	height: 200px;

	&__txt{
		font-size: 37px;
		font-weight: bold;
		letter-spacing: .08em;
		text-indent: .08em;
		line-height: 1;
		display: inline-block;
		color: #fff;
	}

	@include max_screen($break-point) {
		height: 150px;
		margin-bottom: 40px;

		&__txt{
			font-size: 28px;
		}
	}
}


// header --------------------
.header{
	height: 110px;

	&__inner{
		width: 960px;
		margin: 0 auto;
		position: relative;
	}

	&__logo{
		position: absolute;
		left: 0;
		top: 32px;
	}

	&__access{
		position: absolute;
		right: 438px;
		top: 60px;
		color: #004986;
		font-size: 12px;
		line-height: 1;
		@include txt_link_opacity(none,#004986,#004986,none);

		.icon{
			margin-right: 10px;
			display: inline-block;
		}
	}

	&__privacy{
		position: absolute;
		right: 260px;
		top: 60px;
		color: #004986;
		font-size: 12px;
		line-height: 1;
		@include txt_link_opacity(none,#004986,#004986,none);

		.icon{
			margin-right: 10px;
			display: inline-block;
		}
	}

	&__tel{
		position: absolute;
		right: 8px;
		top: 32px;

		&__inner{
			position: relative;
			padding-left: 30px;

			&:before{
				content: "";
				position: absolute;
				left: 0;
				bottom: 10px;
				width: 24px;
				height: 16px;
				background: url("/assets/img/common/tel.png") no-repeat center top;
				background-size: 100% auto;
			}
		}

		.txt{
			color: #004986;
			font-size: 11px;
		}

		.numb{
			color: #004986;
			font-size: 28px;
			font-weight: bold;
			font-family : Arial, sans-serif;
		}
	}

	@include max_screen($break-point) {
		height: 60px;
		border-top: 4px solid #004986;

		&__logo {
			position: absolute;
			left: 20px;
			top: 11px;
			width: 140px;

			img{
				width: 100%;
			}
		}

		&__inner {
			width: 100%;
			height: 100%;
		}

		&__access{
			display: none;
		}

		&__privacy{
			display: none;
		}

		&__tel{
			display: none;
		}
	}
	//SP
}

// navi --------------------
.navi{
	height: 60px;
	background: #004986;

	&__list{
		display: flex;
		width: 960px;
		margin: 0 auto;
		height: 60px;

		&__item{
			color: #fff;
			font-size: 16px;
			font-weight: bold;
			letter-spacing: .18em;
			text-indent: .18em;
			display: flex;
			justify-content: center;
			align-items: center;
			border-right: 1px dotted rgba(255,255,255,0.6);
			width: 160px;
			box-sizing: border-box;
			@include txt_link_opacity(none,#fff,#fff,none);

			&:first-child{
				border-left: 1px dotted rgba(255,255,255,0.6);
			}
		}
	}

	@include max_screen($break-point) {

		display: none;
		position: absolute;
		top: 60px;
		background: #004986;
		z-index: 20;
		width: 100%;
		height: auto;

		&__list {
			display: block;
			width: 100%;
			height: auto;

			&__item {
				width: 100%;
				text-align: center;
				padding: 20px 0;
				border-bottom: 1px dotted #fff;
				border-right: none;

				&:first-child{
					border-left: none;
				}
			}
		}
	}
	//SP
}

// navi__btn --------------------

.navi__btn{
	display: none;

	@include max_screen($break-point) {

		display: block;
		position: absolute;
		top: 29px;
		right: 20px;
		/*padding: 22px 20px;*/
		display: block;

		span {
			width: 22px;
			height: 2px;
			box-sizing: border-box;
			background: #004986;
			position: relative;
			display: block;
		}

		span:before {
			content: "";
			width: 22px;
			height: 2px;
			background: #004986;
			position: absolute;
			top: -8px;
			left: 0;
		}

		span:after {
			content: "";
			width: 22px;
			height: 2px;
			background: #004986;
			position: absolute;
			bottom: -8px;
			left: 0;
		}
	}
	//SP
}

/*----- navi__open -----*/
@include max_screen($break-point) {
	.navi__open .navi__btn span {
		transform: rotate(45deg);
	}

	.navi__open .navi__btn span:before {
		transform: rotate(-90deg);
		top: 0;
	}

	.navi__open .navi__btn span:after {
		display: none;
	}

	.navi__open .navi {
		display: block;
	}
}
//SP

/*----- navi__sp__contact -----*/
.sp__contact{
	display: none;

	@include max_screen($break-point) {
		display: block;

		&__access{
			font-size: 16px;
			line-height: 1;
			text-align: center;
			color: #fff;
			@include txt_link_opacity(none,#fff,#fff,none);
			padding: 20px 0;
			border-bottom: 1px dotted #fff;
			font-weight: bold;
		}

		&__privacy{
			font-size: 16px;
			line-height: 1;
			text-align: center;
			color: #fff;
			@include txt_link_opacity(none,#fff,#fff,none);
			padding: 20px 0;
			border-bottom: 1px dotted #fff;
			font-weight: bold;
		}

		&__tel{
			text-align: center;
			color: #fff;
			@include txt_link_opacity(none,#fff,#fff,none);
			padding: 20px 0;
			font-weight: bold;

			&__inner{
				position: relative;
			}

			.txt{
				color: #fff;
				font-size: 14px;
				margin-bottom: 5px;
			}

			.numb{
				color: #fff;
				font-size: 30px;
				font-weight: bold;
				font-family : Arial, sans-serif;
			}
		}
	}
	//SP
}

// footer --------------------
.footer{
	position: relative;
	background: #434343;
	height: 207px;

	&__txt{
		position: absolute;
		left: 68px;
		top: 58px;
		text-align: left;
		color: #fff;

		.name{
			font-size: 15px;
			letter-spacing: .02em;
			margin-bottom: 20px;
		}

		.address{
			font-size: 13px;
			letter-spacing: .02em;
			line-height: percentage(26/13);
		}
	}

	&__copyright{
		position: absolute;
		right: 69px;
		top: 130px;
		font-size: 10px;
		letter-spacing: .02em;
		font-family : Arial, sans-serif;
		color: #fff;
	}

	@include max_screen($break-point) {
		height: auto;
		padding: 30px 20px 10px 20px;

		&__txt{
			position: static;
			margin-bottom: 30px;

			.name{
				font-size: 14px;
				letter-spacing: .02em;
				margin-bottom: 20px;
			}

			.address{
				font-size: 13px;
				letter-spacing: .02em;
				line-height: percentage(26/13);
			}
		}

		&__copyright{
			position: static;
			text-align: center;
		}
	}
	//SP
}
